import React from "react";
import CommonListItemsComp from "../common/List";

const ListBuildingManagers = (props) => {
    return (
        <>
            <CommonListItemsComp {...props} />
        </>
    )
}

export default ListBuildingManagers;
